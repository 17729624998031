// Invoke 'strict' JavaScript mode
'use strict';

// Create the 'articles' service
angular.module('loginapp').factory('Wachtwoordverzoek', ['$resource', function($resource) {

    // Use the '$resource' service to return an article '$resource' object
    var Wachtwoordverzoek = $resource('../api/login/wachtwoordverzoek',
        {},
        {
            create: {
                method: 'POST'
            }
         }
    );

    return Wachtwoordverzoek;
}]);

