angular.module('loginapp')


	.controller('wachtwoordresettenCtrl', ['$scope', '$stateParams', '$window', '$state', 'Wachtwoord', function($scope, $stateParams, $window, $state, Wachtwoord){

        console.log("initiating wachtwoordresettenCtrl");

        //bij de start: meteen kijken of de resetkey wel bestaat
        Wachtwoord.verify(
            {
                userId: $stateParams.userId,
                resetkey: $stateParams.resetkey
            },
            function(result){
                if(result.status != 'actief'){
                    return $state.go('verlopen');
                }
                console.log(result);
            },
            function(error){
                console.log(error);
                return $state.go('verlopen');
            }
        );

        //=== variabelen private ===
		
		//=== variabelen scope ===
        $scope.errorMessage = "";

		//=== functies ===
		$scope.verstuur = function(){
            $scope.errorMessage = "";
            //validatie van enkele punten
            if(!$scope.wachtwoord1 || !$scope.wachtwoord2) {
                return;
            }
            if($scope.wachtwoord1 != $scope.wachtwoord2){
                $scope.errorMessage = "De ingevoerde wachtwoorden komen niet overeen.";
                return;
            }
            if($scope.wachtwoord1.length <5){
                $scope.errorMessage = "De nieuwe wachtwoord moet minimaal 5 karakters lang zijn.";
                return;
            }
            var wachtwoord = new Wachtwoord({
                wachtwoord1: $scope.wachtwoord1,
                wachtwoord2: $scope.wachtwoord2
            });
            wachtwoord.$reset(
                {
                    userId: $stateParams.userId,
                    resetkey: $stateParams.resetkey
                },
                function(result) {
                    //inloggen is gelukt. redirecten
                    return $state.go('wachtwoordresetten2');
                },
                function(error) {
                    console.log(error);
                    if(error.status == 404){
                        return $state.go('verlopen');
                    }
                    else{
                        $scope.errorMessage = error.data;
                    }

                });
		}

	}]);






