// Invoke 'strict' JavaScript mode
'use strict';

// Create the 'articles' service
angular.module('loginapp').factory('Wachtwoord', ['$resource', function($resource) {

    // Use the '$resource' service to return an article '$resource' object
    var Wachtwoord = $resource('../api/login/wachtwoord/:userId/:resetkey',
        {},
        {
            reset: {
                method: 'POST'
            },
            verify: {
            method: 'GET'
        }
            //delete is standaard al geïmplementeerd
         }
    );

     return Wachtwoord;
}]);

