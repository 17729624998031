angular.module('loginapp')


	.controller('loginCtrl', ['$scope', '$state', '$stateParams', '$window', 'UserLogin', function($scope, $state, $stateParams, $window, UserLogin){

        //=== variabelen private ===

		//=== variabelen scope ===
        $scope.errorMessage = "";

		//=== functies ===
		$scope.login = function(){
            $scope.errorMessage = "";
            //validatie van enkele punten
            if(!$scope.username || !$scope.password) {
                return;
            }
            var userLogin = new UserLogin({
                username: $scope.username,
                password: $scope.password
            });
            userLogin.$login(
                {},
                function(result) {
                    //inloggen is gelukt
                    //decode om role op te halen
                    var decoded = jwt_decode(result.token);

                    //opslaan van token in sessionStorage
                    $window.localStorage["token_taalblobs_" + document.domain] = result.token;
                    // redirecten
                    if(result.role == 'rtadmin' || result.role == 'thuisadmin' || result.role == 'schooladmin'){
                        //kijken of de licentie niet is verlopen
                        var licentieTot = new Date(decoded.licentie.licentieTot);
                        if(licentieTot<Date.now()){
                            $window.location.href = '../../admin/#!/' + decoded._id + '/licentie';
                            return;
                        }
                        $window.location.href = '../../admin/#!/' + decoded._id + '/groepen';
                    }
                    else if(result.role == 'superadmin'){
                        //superadmin
                        //$window.location.href = '../../superadmin/#!/statistieken';
                        $window.location.href = '../../app/super/' + decoded._id + '/statistieken'
                    }
                    else if(result.role == 'office'){
                        //office
                        //$window.location.href = '../../superadmin/#!/admins';
                        $window.location.href = '../../app/super/' + decoded._id + '/admins'
                    }
                    else{
                        //leerling
                        //kijken of de licentie niet is verlopen
                        var licentieTot = new Date(decoded.licentie.licentieTot);
                        if(licentieTot<Date.now()){
                            $state.go('accountverlopen');
                            return;
                        }
                        if(decoded.licentie.geblokkeerd.aantalMax || decoded.licentie.geblokkeerd.administrator || decoded.licentie.geblokkeerd.betalingsachterstand){
                            $state.go('accountgeblokkeerd');
                            return;
                        }
                        $window.location.href = '../../leerling/#!/' + decoded._id + '/taken';
                    }

                },
                function(error) {
                    //console.log(error);
                    delete $window.localStorage["token_taalblobs_" + document.domain];
                    if(error.status == 429){
                        $scope.errorMessage = "Er zijn in een korte tijd te veel pogingen gedaan om in te loggen. De volgende poging is mogelijk om " + error.data.error.nextValidRequestDate;
                        return;
                    }
                    $scope.errorMessage = "De combinatie van gebruikersnaam en wachtwoord is onbekend. Controleer de gegevens en probeer het opnieuw."
                });
		}

	}]);






