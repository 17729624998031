// Invoke 'strict' JavaScript mode
'use strict';

// Create the 'articles' service
angular.module('loginapp')
    .factory('UserLogin', ['$resource', function($resource) {

        // Use the '$resource' service to return an article '$resource' object
        var UserLogin = $resource('../api/login/userlogin',
            {},
            {
                login: {
                    method: 'POST'
                }
                //delete is standaard al geïmplementeerd
             }
        );

         return UserLogin;
    }])

    .factory('authInterceptor', function ($rootScope, $q, $window) {
        return {
            request: function (config) {
                config.headers = config.headers || {};
                if ($window.localStorage["token_taalblobs_" + document.domain]) {
                    config.headers.Authorization = 'Bearer ' + $window.localStorage["token_taalblobs_" + document.domain];
                }
                return config;
            },
            responseError: function (rejection) {
                if (rejection.status === 401) {
                    // handle the case where the user is not authenticated
                }
                return $q.reject(rejection);
            }
        };
    });