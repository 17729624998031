angular.module('loginapp')


	.controller('wachtwoordopvragenCtrl', ['$scope', '$stateParams', '$state', 'Wachtwoordverzoek', function($scope, $stateParams, $state, Wachtwoordverzoek){

        console.log("initiating wachtwoordopvragenCtrl");

        //=== variabelen private ===
		
		//=== variabelen scope ===
        $scope.errorMessage = "";

		//=== functies ===

		$scope.verstuur = function(methode){
            $scope.errorMessage = "";
            if(methode == 'email' && !$scope.email) return;
            if(methode == 'username' && !$scope.username) return;
            var wachtwoordverzoek = new Wachtwoordverzoek({
                email: (methode == 'email') ? $scope.email : undefined,
                username: (methode == 'username') ? $scope.username : undefined
            });
            wachtwoordverzoek.$create(
                {},
                function(result) {
                    //inloggen is gelukt. redirecten
                    console.log(result);
                    $state.go('wachtwoordopvragen2');
                },
                function(error) {
                    console.log(error);
                    $state.go('wachtwoordopvragen2');
                });
		}

	}]);






